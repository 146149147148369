
import {required, minLength, maxLength, numeric, email} from 'vuelidate/lib/validators';
import Validation from './Validation';
// import UploadFile from "./UploadFile";
import countries from "../countries";
import axios from 'axios';

function parseXmlToJson(xml) {
    const json = {};
    for (const res of xml.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
        const key = res[1] || res[3];
        const value = res[2] && parseXmlToJson(res[2]);
        json[key] = ((value && Object.keys(value).length) ? value : res[2]) || null;
    }
    return json;
}

export default {
    template: `
        <div>
            <form @submit.prevent="send()" action="" v-if="!formSend">
                <div class="row">
                    <div class="col-12">
                        <h2 class="section-title mb-4">Apply</h2>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <p class="fs-4 fw-bold">Company Information</p>
                        <p class="fs-5 fw-bold">Tell us about your company.</p>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.companyName.$error}">
                            <input type="text" class="form-control" id="company-name" v-model="$v.form.companyName.$model" :class="{'is-touched': form.companyName.length}">
                            <label for="company-name" class="form-label"><span class="text-danger">*</span>Company name</label>
                            <validation :field="form.companyName" :vfield="$v.form.companyName"></validation>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.companyWebsite.$error}">
                            <input type="text" class="form-control" id="company-website" v-model="$v.form.companyWebsite.$model" :class="{'is-touched': form.companyWebsite.length}">
                            <label for="company-website" class="form-label"><span class="text-danger">*</span>Company Website</label>
                            <validation :required="'Please specify a valid web link'" :field="form.companyWebsite" :vfield="$v.form.companyWebsite"></validation>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.taxpayerIdentificationNumber.$error}">
                            <input type="text" class="form-control" id="taxpayer-identification-number" v-model="$v.form.taxpayerIdentificationNumber.$model" :class="{'is-touched': form.taxpayerIdentificationNumber.length}">
                            <label for="taxpayer-identification-number" class="form-label"><span class="text-danger">*</span>Taxpayer identification number</label>
                            <validation :field="form.taxpayerIdentificationNumber" :vfield="$v.form.taxpayerIdentificationNumber"></validation>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <p class="fs-4 fw-bold">Company address</p>
                    </div>
                    <div class="col-12">
                        <div class="form-group" :class="{'is-invalid': $v.form.country.$error}">
                            <select id="country" class="form-select" v-model="$v.form.country.$model" :class="{'is-touched': form.country.length}">
                                <option value="" disabled></option>
                                <option :value="country" v-for="country in countries">{{ country }}</option>
                            </select>
                            <label for="country" class="form-label"><span class="text-danger">*</span>Country</label>
                            <validation :field="form.country" :vfield="$v.form.country"></validation>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.state.$error}">
                            <input type="text" class="form-control" id="state" v-model="$v.form.state.$model" :class="{'is-touched': form.state.length}">
                            <label for="state" class="form-label"><span class="text-danger">*</span>State / Province</label>
                            <validation :field="form.state" :vfield="$v.form.state"></validation>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.address.$error}">
                            <input type="text" class="form-control" id="address" v-model="$v.form.address.$model" :class="{'is-touched': form.address.length}">
                            <label for="address" class="form-label"><span class="text-danger">*</span>Address</label>
                            <validation :field="form.address" :vfield="$v.form.address"></validation>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.zip.$error}">
                            <input type="text" class="form-control" id="zip" v-model="$v.form.zip.$model" :class="{'is-touched': form.zip.length}">
                            <label for="zip" class="form-label"><span class="text-danger">*</span>Zip / Postal Code</label>
                            <validation :field="form.zip" :vfield="$v.form.zip"></validation>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.city.$error}">
                            <input type="text" class="form-control" id="city" v-model="$v.form.city.$model" :class="{'is-touched': form.city.length}">
                            <label for="city" class="form-label"><span class="text-danger">*</span>City</label>
                            <validation :field="form.zip" :vfield="$v.form.city"></validation>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <p class="fs-4 fw-bold">Contact</p>
                        <p class="fs-5 fw-bold">Representative of the company</p>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.name.$error}">
                            <input type="text" class="form-control" id="name" v-model="$v.form.name.$model" :class="{'is-touched': form.name.length}">
                            <label for="name" class="form-label"><span class="text-danger">*</span>Name</label>
                            <validation :field="form.name" :vfield="$v.form.name"></validation>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.surname.$error}">
                            <input type="text" class="form-control" id="surname" v-model="$v.form.surname.$model" :class="{'is-touched': form.surname.length}">
                            <label for="surname" class="form-label"><span class="text-danger">*</span>Surname</label>
                            <validation :field="form.surname" :vfield="$v.form.surname"></validation>
                        </div>
                    </div>
                    <div class="col-4 col-lg-1">
                        <div class="form-group" :class="{'is-invalid': $v.form.prefix.$error}">
                            <input type="text" class="form-control" id="prefix" v-model="$v.form.prefix.$model" :class="{'is-touched': form.prefix.length}">
                            <label for="prefix" class="form-label"><span class="text-danger">*</span>Prefix</label>
                            <validation :counter="false" :field="form.prefix" :vfield="$v.form.prefix"></validation>
                        </div>
                    </div>
                    <div class="col-8 col-lg-5">
                        <div class="form-group" :class="{'is-invalid': $v.form.phoneNumber.$error}">
                            <input type="text" class="form-control" id="phone" v-model="$v.form.phoneNumber.$model" :class="{'is-touched': form.phoneNumber.length}">
                            <label for="phone" class="form-label"><span class="text-danger">*</span>Phone Number</label>
                            <validation :field="form.phoneNumber" :vfield="$v.form.phoneNumber"></validation>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.emailAddress.$error}">
                            <input type="text" class="form-control" id="email" v-model="$v.form.emailAddress.$model" :class="{'is-touched': form.emailAddress.length}">
                            <label for="email" class="form-label"><span class="text-danger">*</span>E-mail address</label>
                            <validation :field="form.emailAddress" :vfield="$v.form.emailAddress"></validation>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <p class="fs-4 fw-bold">Product Details</p>
                        <p class="fs-5 fw-bold">Tell us about your product(s) or service(s)</p>
                    </div>
                    <div class="col-12">
                        <div class="form-group" :class="{'is-invalid': $v.form.tellUs.$error}">
                            <textarea class="form-control" id="tellus" rows="1" :class="{'is-touched': form.tellUs.length}" v-model="$v.form.tellUs.$model"></textarea>
                            <label for="tellus" class="form-label"><span class="text-danger">*</span>Tell us about your product(s) or service(s)<span class="mask"></span></label>
                            <validation :field="form.tellUs" :vfield="$v.form.tellUs"></validation>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group" :class="{'is-invalid': $v.form.differentiates.$error}">
                            <textarea class="form-control" id="differentiates" rows="1" :class="{'is-touched': form.differentiates.length}" v-model="$v.form.differentiates.$model"></textarea>
                            <label for="differentiates" class="form-label"><span class="text-danger">*</span>What differentiates your product/service from the competition?<span class="mask"></span></label>
                            <validation :field="form.differentiates" :vfield="$v.form.differentiates"></validation>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.industry.$error}">
                            <select id="industry" class="form-select" v-model="form.industry" :class="{'is-touched': form.industry.length}">
                                <option value="" disabled></option>
                                <option :value="industry" v-for="industry in industries">{{ industry }}</option>
                            </select>
                            <label for="industry" class="form-label"><span class="text-danger">*</span>What industry do you serve?</label>
                            <validation :field="form.industry" :vfield="$v.form.industry"></validation>
                        </div>
                    </div>
            
                    <div class="w-100"></div>
            
                    <div class="col-lg-6">
                        <div class="form-group" :class="{'is-invalid': $v.form.deployment.$error}">
                            <select id="deployment" class="form-select" v-model="form.deployment" :class="{'is-touched': form.deployment.length}">
                                <option value="" disabled></option>
                                <option :value="deployment" v-for="deployment in deployments">{{ deployment }}</option>
                            </select>
                            <label for="deployment" class="form-label"><span class="text-danger">*</span>What is your deployment model?</label>
                            <validation :field="form.deployment" :vfield="$v.form.deployment"></validation>
                        </div>
                    </div>
            
                    <div class="w-100"></div>
            
                    <!--<div class="col-lg-6 mt-4">-->
                        <!--<p class="fs-5 fw-bold">Attachments <span class="text-black-50">(optional)</span></p>-->
                        <!--<p class="fs-6 fw-bold">You can attach only PDF file (25mb max total)</p>-->
                    <!--</div>-->
                    <!--<div class="w-100"></div>-->
                    <!--<div class="col-lg-6">-->
                        <!--<upload-file></upload-file>-->
                    <!--</div>-->
                    <!--<div class="w-100"></div>-->
            
                    <div class="col-lg-6 mt-2">
                        <div class="form-group">
                            <input type="text" class="form-control" id="links" v-model="form.links" :class="{'is-touched': form.links.length}">
                            <label for="links" class="form-label">Any portfolio links (optional)</label>
                        </div>
                    </div>
            
                    <div class="col-12 mt-4">
                        <p class="fs-5 fw-bold">Confirmation</p>
                        <!--<p class="fs-6 fw-bold">Once you submit this application, you will not be able to make any changes.</p>-->
                    </div>
            
                    <div class="col-12 mb-4">
                        <div class="form-checkbox" :class="{'is-invalid': form.confirm1 === false}">
                            <input type="checkbox" id="confirm-1" value="true" v-model="form.confirm1">
                            <label for="confirm-1">
                                <span class="custom-checkbox"></span>
                                <span class="text-danger">*</span>I authorize Alior Bank SA to use the provided email address and mobile
                                telephone number exclusively for the purpose of responding to my message/ enquiry.
                            </label>
                            <div v-if="form.confirm1 === false" class="d-flex align-items-center justify-content-between">
                                <div class="error">
                                    <svg class="me-2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V6.5H5.5V7.5ZM5.5 5.5H4.5V2.5H5.5V5.5Z" fill="#E25948"/>
                                    </svg>
                                    <span>This field is required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div class="col-12 text-center mt-5">
                        <button class="btn btn-xl btn-outline-dark text-uppercase">
                            <span></span><span></span>SEND
                        </button>
                    </div>
            
                </div>
            </form>
            
            <div v-if="formSend" class="thx">
                <div class="row">
                    <div class="col-12">
                        <h2 class="section-title mb-4">Confirmation</h2>
                        <p class="fw-bold fs-4">Your application has been received.</p>
                        <p class="fs-6">Thank you for your interest in the RBL_START.</p>
                        <p class="fs-6">We review each company's application thoroughly. Generally we respond within 30 days after your application is received to confirm additional information required to move your application forward.</p>
                        <p class="fs-6">If you have questions about the program, please send a message for us (ACCELERATOR@ALIOR.PL)</p>
                    </div>
                </div>
            </div>
        </div>
	`,
    name: 'formComponent',
    components: {Validation},
    data() {
        return {
            formSend: false,

            industries: [
                'AI / Automation',
                'AR / VR',
                'Advanced Analytics',
                'Authentication',
                'Banking Services',
                'Blockchain',
                'Card Tech',
                'Consulting',
                'Consumer Products',
                'Crowd Funding / Lending',
                'Cybersecurity',
                'Education Tech',
                'Environmental, Social & Governance',
                'Finance',
                'HR',
                'Healthcare',
                'IOT',
                'Infrastructure',
                'Insurance',
                'Investment Tech',
                'Marketing / Adtech',
                'Mobile Solutions',
                'Payments',
                'Personal Financial Management',
                'Real Estate / Mortgage',
                'Regulation Tech',
                'Remittance',
                'Social / Communication Tech',
                'Other',
            ],
            deployments: ['Alpha', 'Beta', 'Concept', 'Production'],
            countries: countries,

            form: {
                companyName: '',
                companyWebsite: '',
                taxpayerIdentificationNumber: '',
                country: '',
                state: '',
                address: '',
                zip: '',
                city: '',
                name: '',
                surname: '',
                prefix: '',
                phoneNumber: '',
                emailAddress: '',
                tellUs: '',
                differentiates: '',
                industry: '',
                deployment: '',
                links: '',
                confirm1: '',
                // confirm2: '',
            }
        }
    },
    methods: {
        send() {
            const $this = this;
            this.$v.$touch();
            if (this.form.confirm1 === '') this.form.confirm1 = false;
            if ( !this.$v.$invalid && this.form.confirm1 ) {
                // const data = $this.form;
                const additionalField = `
country: ${this.form.country} | 
state: ${this.form.state} | 
address: ${this.form.address} | 
zip: ${this.form.zip} | 
city: ${this.form.city} | 
tellUs: ${this.form.tellUs} | 
differentiates: ${this.form.differentiates} | 
industry: ${this.form.industry} | 
deployment: ${this.form.deployment} | 
links: ${this.form.links}
                `;

                const data = {
                    application_ID: '1502',
                    partner_id: 'FIN_RBL_F',
                    content: '1200',

                    name: this.form.name,
                    surname: this.form.surname,
                    mail: this.form.emailAddress,
                    company: this.form.companyName + ' | ' + this.form.companyWebsite,
                    phone: this.form.prefix + ' ' + this.form.phoneNumber,
                    date: '',
                    hour: '',
                    amont: '',
                    offer: '',
                    pesel: '',
                    cif: '',
                    regon: '',
                    nip: this.form.taxpayerIdentificationNumber,
                    security: '',
                    agreement_one: this.form.confirm1,
                    agreement_two: '',
                    agreement_three: '',
                    additionalField: additionalField,
                    agreement_cust_invitation: '',
                    attachment: '',
                };

                const params = new URLSearchParams(data);
                const str = params.toString();

                const url = 'https://form.aliorbank.pl/fk_forms/dps';
                // const url = 'http://localhost/_alior/RBL/app/form.php';
                axios.post(url, str)
                    .then(function (response) {
                        const result = parseXmlToJson(response.data);
                        if ( result && result.response && result.response.taskId && result.response.taskId !== '0' ) {
                            $this.formSend = true;
                        } else {
                            $this.formSend = false;
                            alert('An error has occurred, please try again later');
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert('An error has occurred, please try again later');
                    });
            }
        },
    },
    validations: {
        form: {
            companyName: {required, maxLength: maxLength(75)},
            companyWebsite: {required, maxLength: maxLength(75)},
            taxpayerIdentificationNumber: {required, numeric, maxLength: maxLength(20)},
            country: {required,},
            state: {required, maxLength: maxLength(75)},
            address: {required, maxLength: maxLength(175)},
            zip: {required, maxLength: maxLength(20)},
            city: {required, maxLength: maxLength(40)},
            name: {required, maxLength: maxLength(40)},
            surname: {required, maxLength: maxLength(40)},
            prefix: {required, numeric, maxLength: maxLength(4)},
            phoneNumber: {required, numeric, minLength: minLength(9), maxLength: maxLength(18)},
            emailAddress: {required, maxLength: maxLength(75), email},
            tellUs: {required, maxLength: maxLength(500)},
            differentiates: {required, maxLength: maxLength(500)},
            industry: {required},
            deployment: {required},
        },
    },
}