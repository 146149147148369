const rblTeam = [
  {
    img: 'tomasz_serwan.jpg',
    name: 'Tomasz Serwan',
    position: 'Head of Unit RBL_VC',
    content:
      'Tomasz is the head of the RBL_VC fund. Hej is responsible for the team management, creation and iplementarion of the strategy. Tomasz co-creates the Startup ecosystem since 2009. During his career in reputable VC funds, has carried out several dozen investments in companies from various industries. Many of them operate on a global scale. Acting as an independent specialist on the capital market, Tomasz was an advisor on mergers and acquisitions, both on the sale and purchase side. He was also responsible for creating strategies, process development and optimization for entities with high growth dynamics. During his career, he occupied a place „on both sides of the barricade”, both reporting and exercising ownership supervision. Last years he supported portfolio companies: PayPo and Autenti as an Supervisory Board Member.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/tomasz-serwan-5213333b/',
      },
    ],
  },
  {
    img: 'jakub_maj.jpg',
    name: 'Jakub Maj',
    position: 'Investment Manager CVC',
    content:
      'Jakub Maj – Investment Manager, responsible for relationships with startups, VCs and the investment process.  He has as over 15 years of experience in incubation, venture capital and startup management. He worked for the leading German incubator Rocket Internet, performance marketing network AWIN in Germany (Axel Springer Group), and a daily-deal startup in Hungary. He gained his venture capital experience in reputable funds, including SevenVentures, the venture capital arm of ProSiebenSat1 in Germany, Bvalue.vc in Poland, and Rebate Networks in Germany. Jakub has been an advisor to startups in the field of venture capital, M&A, and business development. He was part of the investment committee at AIP, the leading Polish incubator, as well as part of the investment committee at National Center of Research and Development (NCBiR) in Poland.',
    social: [
      { name: 'LinkedIn', url: 'https://www.linkedin.com/in/jakubmaj/' },
    ],
  },
  {
    img: 'luiza_nowacka_samczuk.jpg',
    name: 'Luiza Nowacka-Samczuk',
    position: 'Investment Associate CVC at RBL_VC',
    content:
      "Luiza Nowacka-Samczuk is a part of RBL_VC's investment team, where her tasks include start-up’s scouting and recommending them for the investment. She co-manages the investment process, with particular focus on legal issues. For over 9 years Luiza has been co-creating a start-up ecosystem, currently acting on the side of the investor, previously providing business and legal advisory to startups. Moreover, her track-record includes the creation of over 200 business plans for a total investment amount of over PLN 2 billion. She has helped entrepreneurs to obtain funds for the implementation of their business projects (bank loans, VC funds, private investors, grants). She also worked at renowned Polish law firms specializing in M&A and new technology law. Founder of several start-ups. Juror of start-up competitions. A certified business negotiator with a degree from St Andrews University in Scotland. She completed doctoral studies at Warsaw School of Economics. ",
    social: [
      { name: 'LinkedIn', url: 'https://www.linkedin.com/in/luizanowacka/' },
    ],
  },
  {
    img: 'sebastian_bieniek.jpg',
    name: 'Sebastian Bieniek',
    position: 'Investment Specialist CVC',
    content:
      'Sebastian Bieniek belongs to the RBL_VC fund team, supporting it mainly by: investment potential analysis, market research and internal startups valuation. He worked as a Fund Manager in the first Polish independent investment fund company – OPERA TFI. He dealt with investments in innovative companies, both national and foreign. Sebastian holds a Polish Investment Advisor licence. ',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/sebastian-bieniek/',
      },
    ],
  },
];

const liders = [
  {
    img: 'pawel_franczyk.jpg',
    name: 'Paweł Franczyk',
    position: 'Managing Director of the<br/> Digital Channels Division',
    content:
      'Paweł has almost 20 years of experience in the financial industry. He worked in several banks, mainly in the areas of product management and personal finance projects, or building solutions and cooperation with eCommerce market leaders. He has been associated with innovations at Alior Bank since 2015. Startup mentor in the acceleration program. High-tech enthusiast and ambasador. Graduated of the University of Economics in Krakow in the field of Innovation and Entrepreneurship. Motorcycles and snowboarding fan. Backpacker.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/pawel-franczyk-9a13812',
      },
    ],
  },
  {
    img: 'kamila_winceciak.jpg',
    name: 'Kamila Wincenciak',
    position: 'Head of Innovation and Fintech<br/> Department at Alior Bank',
    content:
      'Kamila is a manager with over 10 years of experience in the field of financial technology development. Majority of this time Kamila has been associated with Alior Bank, where she co-created and developed digital currency platforms in 2012-2016. In 2017 she has joined Innovation Lab where she led the team responsible for cooperation with tech companies and the implementation of fintech solutions at the bank. In this area, she co-created Alior Bank`s strategy for 2018-2020, which resulted in the development of the RBL_ innovation laboratory and RBL_START acceleration program for startups, in 2018. </br>In recent years Kamila has worked in PayU as a Head of Strategy and Development responsible for strategic projects and partnerships, new sales channels and development of the product offer in Poland. </br>Kamila has been associated with the fintech ecosystem for many years as a startup mentor and coordinator of acceleration projects, a lecturer at student workshops and a panelist at Polish and international conferences. Kamila majored in Finance and Accounting at the Warsaw School of Economics and European Studies at the University of Warsaw. In her personal life, as a mother of two children, she constantly co-creates and participates in many interesting parenting projects ;). She loves traveling, good cuisine and sports.',
    social: [{ name: '', url: '' }],
  },

  {
    img: 'michal_kempa.jpg',
    name: 'Michał Kempa',
    position: 'Head of FinTech Partnerships',
    content:
      'In Alior Bank almost from its foundation. He has quite a versatile experience in banking. Prior to Fintech department he was a teller in the branch, worked in controlling, sales management and investor relations. Michal is open-minded with “there is always some room for improvement” mindset and focus on cooperation with startups opportunities. Innovators rule! 😊',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/micha%C5%82-kempa-53b598157/',
      },
    ],
  },
  {
    img: 'angelina_szczepaniec.jpg',
    name: 'Angelina Szczepaniec',
    position: 'Director of Fintech Partnerships',
    content:
      'Experienced manager implementing strategic, innovative projects for the bank and entrepreneurs. At the Bank, she is looking for the most innovate FinTech solutions on the Polish and global market. She is responsible for pre-verification of Fin Tech solutions in terms of innovation, profitability and the possibility of implementation in the company. Mentor in the acceleration program. Project Management postgraduate studies at SGH - with Prince 2 and IPMA certificates.',
    social: [],
  },
  {
    img: 'kamil_scisel.jpg',
    name: 'Kamil Ściseł',
    position: 'FinTech and eCommerce<br/> Technology Manager',
    content:
      'Exproenced manager with over 10 years in online projects. Graduated from Jagiellonian University  and took part in postgraduate studies in Project Management. Eager to gain new knowledge in business courses. He takes care of project coordinaton and and pre-verification of E-commerce and Fin Tech solutions.',
    social: [
      { name: 'LinkedIn', url: 'https://pl.linkedin.com/in/kamil-ściseł/' },
    ],
  },
];
const mentors = [
  {
    img: 'marcin_kurczab_pzu.png',
    name: 'Marcin Kurczab',
    position: 'Director of Innovation at PZU',
    content:
      'For 9 years he has been associated with the PZU Group, where he started with project coordination within the Strategy and Project Office, and for 5 years he has been managing the operations of the Innovation Lab. Responsible for establishing Lab and developing the PZU Group Innovation Strategy. As part of the "PZU Ready for Startups" program, his team cooperates with the best startups in Poland and in the world, supporting PZU\'s business units in testing and implementing technological innovations. Annually, Innovation Lab analyzes about 1,000 ideas and startups, of which it runs 8-12 pilot projects mainly in the areas of artificial intelligence, big data, digitization and new interactions with the customers. Examples of innovations include solutions such as the use of artificial intelligence to analyze motor claims and the use of satellite imagery to improve service processes. Marcin is a graduate of the Warsaw School of Economics and the University of Economics in Krakow, he also completed the "Cloud and DevOps" program at the Massachusetts Institute of Technology Professional Education. Privately, he is passionate about rock music, tennis, basketball and Italian espresso.',
    social: [
      {
        name: '',
        url: '',
      },
    ],
    email: '',
  },
  {
    img: 'anna_kosciuczuk.jpg',
    name: 'Anna Kościuczuk',
    position: 'Innovation Expert at PZU',
    content:
      'Anna is associated with the PZU Group since 2013. Since 2017 as an Innovation Expert at the Innovation Lab. She is responsible for analyses, verifications of solutions and technologies available on the market as well as the possibilities of their application. She looks for potential partners, searches for suppliers, establishes contacts with local and international organizations and startups and support business in innovative projects implementation.',
    social: [
      {
        name: '',
        url: '',
      },
    ],
    email: '',
  },
  {
    img: 'katarzyna_jurkowska_pzu.jpg',
    name: 'Katarzyna Jurkowska',
    position: 'Mobile Functional Transformation Coordinator at PZU',
    content:
      "Kasia is an experienced Product Owner in the insurance industry. She coordinated works related to the optimization and implementation of new processes in the IT systems of the PZU Group (including the client's operational database). Currently, she is responsible for defining the vision of the implemented products and supervises their development in the mojePZU mobile application. She knows the standards and good practices in the area of analysis and definition of requirements. She has over 10 years of experience in business analysis.",
    social: [
      {
        name: '',
        url: '',
      },
    ],
    email: '',
  },
  {
    img: 'monika_maluha_pzu.jpg',
    name: 'Monika Maluha',
    position: 'eCommerce process expert at PZU',
    content:
      'Monika has been involved in the insurance industry since 2002. She has experience in implementing new insurance products. She has participated in analytical and design work related to the definition of business processes for insurance services, including the self-service portal. Currently, she works as an expert in the mojePZU mobile application team, where she is involved in co-creation and verification of functional requirements for the application, cooperates with IT analysts and takes part in the development of prototypes of mock-ups dedicated to the mobile application.',
    social: [],
    email: '',
  },
  {
    img: 'kuba_cendrowski.jpg',
    name: 'Jan Jakub Cendrowski ',
    position: 'Director inQUBE University Business Incubator',
    content:
      'Marketing communication expert and business strategist with many years of practice in designing advertising campaigns and online marketing tools. He has gained experience, among others, in the telecommunications industry (Telefonia DIALOG), the chemical industry and as a manager in advertising agencies (including Me & My Friends) and public relations. Winner of industry awards and author of marketing publications in industry media. He worked, among others, for such brands and organisations as Maspex Group (brands: Tymbark, Puchatek, Kubuś), Northfish, Unibail-Rodamco-Westfield (Wroclavia shopping centre), Futuro Finance, ZP Polski Przemysł Spirytusowy, LC Corp, RED Real Estate Development, Instytut Spraw Obywatelskich, Urząd Marszałkowski Województwa Łódzkiego, Łódzka Kolej Aglomeracyjna, International Football Association Board. He has also worked with many startups, creating their marketing strategies from scratch. For many years, he has worked with and lectured at the Wrocław School of Banking and the Institute of Journalism and Social Communication at the University of Wrocław. From February to October 2021, deputy director of the inQUBE University Incubator of Entrepreneurship at the Wrocław University of Economics. From November 2021 - director of this institution. Graduate of philosophy and journalism at the University of Wrocław.',
    social: [{ name: '', url: '' }],
    email: '',
  },

  {
    img: 'jakub_galus.jpg',
    name: 'Jakub Galus',
    position: 'Senior Partner Development Manager at Microsoft',
    content:
      "Kuba works in Microsoft's regional division, building strategic partnerships with major software and SaaS platform providers from Poland, the Czech Republic and Slovakia. Previously, he gained his experience by opening markets in several Central European countries for an Israeli manufacturer of communications hardware and software. He doesn't know all the buzzwords from the LinkedIn gurus, but he knows a bit about how to build a B2B business from scratch using technology partnerships, direct sales and engaging an ecosystem of integrators with limited in-house resources.",
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'piotr_szczepanik.jpg',
    name: 'Piotr Szczepanik ',
    position: 'Enterprise Channel Manager at Microsoft',
    content:
      'Piotr Szczepanik holds the position of Enterprise Channel Manager at Microsoft and is responsible for industry solutions to the financial sector, including banks and insurance companies. Piotr has 20 years of professional experience in sales and delivery of IT systems solutions. He was also responsible for creating business strategies and customer acquisition for innovative solutions dedicated to large integrators and Fintach / Regtech companies. A graduate of the Polish-Japanese Institute of Information Technology, he also holds an MBA from the French Institute of Management.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'justyna_martyniak.jpg',
    name: 'Justyna Martyniak',
    position: 'Microsoft Dynamics 365 Sales Professional',
    content:
      'Justyna is business Application specialist in Microsoft on financial sector. She works with banks and insurance companies on their digital transformation projects. She has over 15 years of experience from banking market where she was responsible for CRM, data analytics and deploying AI into banking processes. As transformation leader Justyna was active member of merger projects and evolution/revolution of one of the biggest financial groups in Poland.  Working past 15 years at BNP Paribas, BGŻ, Sygma Bank, Fortis Bank, she was part of business, process and IT systems’ transformation. In her career Justyna was working on both corporate, SME and retail side of the bank. She has broad experience of building and implementing business applications and complex solutions to improve revenue in banks. She has experience in Product Owner role in Agile projects which can help companies in fast implementations of digital transformation. Justyna finished Mathematics studies at Politechnika Warszawska which gave her very strong knowledge of innovative technologies using AI methods.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'karolina_kowalczyk.jpg',
    name: 'Karolina Kowalczyk',
    position: 'IT Product Partnership Manager at Autenti',
    content:
      'Karolina is on-line innovations and ESG enthusiast, working as IT Product Partnership Manager in Autenti – Trust Services industry, e-Signatures and eID in cloud environment.<br/> Karolina has 15 years of experience in on-line banking and financial services, started in Polish Banking Association. At PayU/NASPERS Group and Allegro Group, the biggest Polish FinTech and e/m-commerce Marketplace, she was responsible for creation of the business development strategy and leading negotiations with top-25 banks and banking groups in Poland, as well as co-ordination of implementing the most innovative e-financial, mobile products in the world.<br/>One of them, the first fully on-line installments payment system – Real 0% Rate Installments PayU on Allegro – became the benchmark of new type of credit products in banking and retail sector.<br/>Karolina brings a unique combination of listening, assimilating multiple data streams and experience from a different fields, creativity but at the same time a team player, with a strong accent on UX.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'robert_grabowski.jpg',
    name: 'Robert Grabowski',
    position: 'Director Product Integration - Mastercard',
    content:
      'Robert is an IT technology enthusiast at Mastercard, with more than a decade of experience in IT team management, software development, integration & testing. Today in his global function as Product Integrator for Real-time Payments at Mastercard, he takes a customer seat thriving for simplicity.<br/>His curiosity & ability to speak about complex solutions with a simple language, has lead him to be Mastercard Academy Speaker and allows him to explore unchartered territories. He is driven by a challenge to explore, have holistic view and whenever he learns he will give that knowledge back by sharing with wider audience. With his many passions for sports, music, coffee & a good conversation, he is approachable and will always find time.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'malgorzata_domagala.jpg',
    name: 'Malgorzata Domagała',
    position: 'Vice President Product Development - Mastercard',
    content:
      'Wiceprezes Mastercard Europe ds Cyfrowych Rozwiązań, ekspert w zakresie płatności bezgotówkowych i otwartej bankowości.<br/>Absolwentka MBA University of Minnesota, Carlson School of Management (USA), Dziennikarstwa (Uniwersytet Warszawski) i Handlu Zagranicznego (Uniwersytet Łódzki).<br/>Dynamiczny menedżer, lider biznesu, z ponad 18-letnim doświadczeniem w pozyskiwaniu klientów, budowaniu relacji i zarządzaniu w sektorze finansów. Skupiona na nowych technologiach w biznesie.<br/>Na obecnym stanowisku od lipca 2022, odpowiedzialna rozwiązania cyfrowe i cyberbezpieczeństwo.<br/>Wcześniej prowadziła projekty z największymi polskimi bankami w Polsce, aktywnie uczestniczyła we wprowadzaniu innowacyjnych metod płatności oraz była odpowiedzialna za strategię i rozwój otwartej bankowości w Mastercard w Polsce.<br/>Wolny czas spędza z mężem i dwójką dzieci, miłośniczka aktywnych form wypoczynku, takich jak narciarstwo i żeglarstwo. Lubi podróżować i poznawać nowe kultury.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'pawel_franczyk.jpg',
    name: 'Paweł Franczyk',
    position:
      'Managing Director of the Digital Channels Division at Alior Bank',
    content:
      'Paweł has almost 20 years of experience in the financial industry. He worked in several banks, mainly in the areas of product management and personal finance projects, or building solutions and cooperation with eCommerce market leaders. He has been associated with innovations at Alior Bank since 2015. Startup mentor in the acceleration program. High-tech enthusiast and ambasador. Graduated of the University of Economics in Krakow in the field of Innovation and Entrepreneurship. Motorcycles and snowboarding fan. Backpacker.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'kamila_winceciak.jpg',
    name: 'Kamila Wincenciak',
    position: 'Head of Innovation and Fintech Department at Alior Bank',
    content:
      'Kamila is a manager with over 10 years of experience in the field of financial technology development. Majority of this time Kamila has been associated with Alior Bank, where she co-created and developed digital currency platforms in 2012-2016. In 2017 she has joined Innovation Lab where she led the team responsible for cooperation with tech companies and the implementation of fintech solutions at the bank. In this area, she co-created Alior Bank`s strategy for 2018-2020, which resulted in the development of the RBL_ innovation laboratory and RBL_START acceleration program for startups, in 2018. </br>In recent years Kamila has worked in PayU as a Head of Strategy and Development responsible for strategic projects and partnerships, new sales channels and development of the product offer in Poland. </br>Kamila has been associated with the fintech ecosystem for many years as a startup mentor and coordinator of acceleration projects, a lecturer at student workshops and a panelist at Polish and international conferences. Kamila majored in Finance and Accounting at the Warsaw School of Economics and European Studies at the University of Warsaw. In her personal life, as a mother of two children, she constantly co-creates and participates in many interesting parenting projects ;). She loves traveling, good cuisine and sports.',
    social: [{ name: '', url: '' }],
  },
  {
    img: 'sergiusz_zbyszewski.jpg',
    name: 'Sergiusz Zbyszewski',
    position: 'Head of Strategic Partnerships Department at Alior Bank',
    content:
      'Sergiusz has many years of experience in the field of sales and product for business customers. Practitioner in building partnership cooperation models. A graduate of the University of Łódź, Faculty of Economics and Sociology, Warsaw School of Economics and Kozminski University, where he received his MBA. At present, he manages the Strategic Partnerships Department at Alior Bank creating key alliances with external partners for the bank.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'jaromir_pelczarski.jpg',
    name: 'Jaromir Pelczarski',
    position: 'Managing Director of the IT Division at Alior Bank',
    content:
      'Jaromir Pelczarski, CIO, expert in digital transformation, banking and fintech. He joined Alior Bank after his experience at Accenture as a cloud practice leader for the financial sector. Since 2011, he was associated with BNP Paribas Bank Polska. Since November 2018, in the rank of Vice President of the Bank, he was responsible, among others, for the area of new technologies, security and change management. Previously, he held the position of Chief Operating Officer within the BGZ BNP Paribas structures. Between 2003 and 2011, he was associated with Fortis Bank. </br>In 2020, Jaromir Pelczarski was awarded the title of "Leader of Digitalisation of the Banking Sector" by Forum for Banking Technology Congress. He is also involved in the development of the startup community as a member of the Sterling Angels business angel association. </br>Jaromir Pelczarski is a graduate of the AGH University of Science and Technology, as well as the University of Bristol and an MBA from the French technical university Ecole des Ponts Paris Tech.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'tadeusz_kifner.jpg',
    name: 'Tadeusz Kifner',
    position: 'Head of IT Architecture & Transformation at Alior Bank',
    content:
      'IT Leader with 25 years of experience in the financial industry. IT architecture designer. Independent consultant and adviser. IT governance & culture evangelist. Experienced in building relationship between business and IT. Involved in strategy & transformation of IT delivery & provision. Startup mentor in the acceleration program. Graduated of the MBA Course and Computer Science studies. Owner of several certificates eg: CISA, CGEIT, ITIL and TOGAF.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'katarzyna_kobylinska.jpeg',
    name: 'Katarzyna Kobylińska',
    position: 'Director of Digitalization Development Department at Alior Bank',
    content:
      'Expert in service design and customer experience management. She has been involved in banking for 12 years. Katarzyna graduated in Economics and Management studies at the University of Warsaw and Service Design at the SPWS University in Warsaw. Leader of Service Design and Innovation team. Together with the team, we are looking for innovative solutions in business based on tools from the world of UX and Service Design. We design for people and people are always at the center of attention. This means fully understanding his daily needs and providing a unique experience in interacting with the service, product, application.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'anna_brzeska.jpg',
    name: 'Anna Brzóska',
    position: 'Head of Remote Process Development at Alior Bank',
    content:
      'Since 2007, she has been associated with the financial industry, mostly focused on remote product sales. Extensive experience built on the basis of cooperation in several banks and financial companies. The subject in which she feels as happy as a clam at high water is related to the implementation of innovative solutions in the sale of banking products at a distance. She has experience in running optimization projects that improve and increase sales. A graduate of the Wyższa Szkoła Biznesu - National Louis University, where she obtained an MBA degree.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'katarzyna_turczyn.jpg',
    name: 'Katarzyna Turczyn',
    position: 'UX Research Manager',
    content:
      'Expert with many years of experience in research, who has devoted the last 5 of them to UX. Ethnologist and anthropologist, graduated from the University of Warsaw with Master Degree. Author of publications in the field of qualitative research and usability testing. Leader of research team, in which everyone feels as an advocate for users and customers and a promoter of the value of research in the development of products and services.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/katarzyna-turczyn/',
      },
    ],
  },
  {
    img: 'ewa_grabowska.jpg',
    name: 'Ewa Grabowska',
    position: 'UX Design Manager',
    content:
      'Ewa leads a team of UX designers. She is responsible for the logic and functioning of digital products developed at the RBL_  Innovation Lab. She takes care of usability and make sure that solutions respond to the real needs of customers. Due to her journalistic education and experience in copywriting, she pays great attention to communication in line with the principles of UX writing. She is an attentive trend watcher and a fan of strategic foresight. She creates the RBL newsletter in which our employees can read the news about technology, innovation and banking of the future.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/ewa-grabowska-b9a514123',
      },
    ],
  },
  {
    img: 'joanna_boron.jpg',
    name: 'Joanna Boroń',
    position: 'Open Banking Manager',
    content:
      'Finance graduate at SGH and a self-proclaimed banker. She has been in banking industry for a while now, usually right in between Technology and Business. This is where she earned her experience with business analysis, operations, product development and project management. Currently responsible for Open API and developing Open Banking products for Clients as well as creating further API-based services for TPPs and Partners. Avid enthusiast of agile mindset with keen interest in the impact of regulatory environment on Customers, Banking and FinTechs.',
    social: [
      { name: 'LinkedIn', url: 'https://pl.linkedin.com/in/joannaboron1' },
    ],
  },
  {
    img: 'marcin_kubus.jpg',
    name: 'Marcin Kobus',
    position: 'Head of Investment Products Division at Alior Bank',
    content:
      'PhD Marcin Kobus - Investment products director, responsible for retail business development. He has above 17 years of experience in developing and implementing new products and services for retail clients. In Alior Bank from the very beginning responsible for saving, bancassurance and investment products offer as well as for payment solutions. Initiated and supervised projects related to new payments methods, biometry and big data, roboadvisory solutions research and AI chatbot`s. Currently involved in developing investment products in Brokerage House Alior Bank. He is a licensed securities broker. Closely cooperates with RBL_ in a field of projects` business opportunities evaluation. Before joining Alior Bank, he worked in Bank BPH and PKO BP. PhD graduated in Economics from Management faculty of Warsaw University, accomplished postgraduate study in Capital Markets Law.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'jakub_grzywacz.jpg',
    name: 'Jakub Grzywacz',
    position: 'Remote Process Development Expert at Alior Bank',
    content:
      'At Alior Bank for almost 7 years, I started my adventure at T-Mobile Usługi Bankowe. My experience began with customer service on the hotline and the sale of banking products. At the moment, in the Remote Process Development Department I am dealing with special tasks and I am not afraid of any challenge :) Outside of work, I love to delve into technological novelties, startups and follow new developments in the financial sector. Work is my second home and I always give 100% of myself',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'katarzyna_kozowy.jpg',
    name: 'Katarzyna Kozowy ',
    position: 'Head of the Telephone Sales Department at Alior Bank',
    content:
      'Always associated with the sale of individual customer products established in the Telephone Sales Department of Alior Bank. Responsible for development and sales in distributed structures and building a customer-friendly environment focused on digital solutions.  In business, she follows a client-centric approach by providing simple and easy solutions focused on building customer relationships. She graduated from the Faculty of Management at the University of Economics in Katowice and from Wyższa Szkoła Biznesu- National Louis University in Nowy Sącz, where she received her MBA.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'sylwia_pniewska_swistak.jpg',
    name: 'Sylwia Pniewska-Świstak',
    position: 'Service design and innovation specialist at Alior Bank',
    content:
      "Specialist in Service and Innovation Design. She has been associated with User Experience Design for the last 5 years. She is a psychology graduate, a design enthusiast , and an observer of human-city relationship. She is working at Alior Bank in Service and Innovation Design Team creating business innovations while taking into account clients' needs.",
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'luiza_nowacka_samczuk.jpg',
    name: 'Luiza Nowacka-Samczuk',
    position: 'Investment Associate CVC at RBL_VC',
    content:
      "Luiza Nowacka-Samczuk is a part of RBL_VC's investment team, where her tasks include start-up’s scouting and recommending them for the investment. She co-manages the investment process, with particular focus on legal issues. For over 9 years Luiza has been co-creating a start-up ecosystem, currently acting on the side of the investor, previously providing business and legal advisory to startups. Moreover, her track-record includes the creation of over 200 business plans for a total investment amount of over PLN 2 billion. She has helped entrepreneurs to obtain funds for the implementation of their business projects (bank loans, VC funds, private investors, grants). She also worked at renowned Polish law firms specializing in M&A and new technology law. Founder of several start-ups. Juror of start-up competitions. A certified business negotiator with a degree from St Andrews University in Scotland. She completed doctoral studies at Warsaw School of Economics. ",
    social: [
      { name: 'LinkedIn', url: 'https://www.linkedin.com/in/luizanowacka/' },
    ],
  },
  {
    img: 'wojciech_dubiel.jpg',
    name: 'Wojciech Dubiel',
    position: 'Head of Product Department at Alior Bank',
    content:
      "Since 2011, he has been working for Alior Bank, where he deals with the development of applications and digital systems in an agile approach as a Product Owner. He has always been customer-focused, previously managing the Contact Centre and currently developing the bank's mobile application. Passionate about new technologies and privately a fan of football and snooker.",
    social: [{ name: '', url: '' }],
  },
  {
    img: 'marcin_bykowski.jpg',
    name: 'Marcin Bykowski',
    position: 'Head of Sales Development Department  at Alior Mobile',
    content:
      'In Alior Bank since this year yet in banking sector working for over 18 years. Since the beggining mainly responsible for Customer Relationship Management in both analytical and operational areas. Currently in Alior Bank responsible for development of mobile business processes, application for Alior Mobile and digital channels sales. In his activities he focuses mainly on customer centricity and analytics utilization in customer loyalization processes.',
    social: [{ name: '', url: '' }],
  },
  {
    img: 'pawel_szczygiel.jpg',
    name: 'Paweł Szczygieł ',
    position: 'Director of the Sales Department at Alior Bank',
    content:
      'For several years in the finance and banking universe, for almost 10 years at Alior Bank. Mainly related with online sales or remote customer service in the Contact Center. Extensive experience in various sales processes gives me the opportunity to combine two areas of the Internet and banking. See you in RBL_2022.',
    social: [{ name: '', url: '' }],
  },
  {
    img: 'rafal_kaminski.jpg',
    name: 'Rafał Kamiński ',
    position: 'Projects Director at Alior Bank',
    content:
      "Leader of Payments & Value Added Services Team in Business Products Department. Rafal lead many key implementation projects of new products for business clients at Alior: e.g. multi currency business debit cards, virtual cards, business debit card with VAS: 'Card with plus', 'Plan Business' debit account for SME, PZU private medical care insurance for enterpreneurs.",
    social: [{ name: '', url: '' }],
  },
  {
    img: 'sebastian_brzuzek.jpg',
    name: 'Sebastian Brzuzek',
    position: 'Senior IT Architect at Alior Bank',
    content:
      'He started programming experience with developing games on Atari 65XE. Currently working as IT Architect at Alior Bank. Former Innovation Manager at Meritum Bank. He was Head of Electronic Banking and Cards Development at Nordea Bank. He can hack with hackers, and talk to business people with understanding of both sides. Responsible for first implementation of electronic signature in online banking in Poland. Open Standards and Open API advocate.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'michal_niemiec.jpg',
    name: 'Michał Niemiec',
    position: 'HelpDesk Team Manager',
    content:
      'Michal has worked at Alior Bank for over 9 years, currently as a HelpDesk team manager. From the beginning he has been associated with the Contact Center with a customer focus. He started his work in customer service at Alior Sync. Privately, he is a fan of football and technical innovations.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'damian_bednarczyk.jpg',
    name: 'Damian Bednarczyk',
    position: 'Information Architecture Manager',
    content:
      'From the beginning of his professional career at Alior Bank. A financier and project manager by education, professional interests are focused around data analysis, information management and knowledge sharing.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'tomasz_pawicki.jpg',
    name: 'Tomasz Pawicki',
    position: 'Head of Savings Products Division',
    content:
      'Expert and manager with 25 years of banking experience in sales and product management. Graduate of the Faculty of Management at Warsaw University. In Alior Bank he manages the Savings Product Division and together with the team creates an offer for individual customers.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'arkadiusz_rak.jpg',
    name: 'Arkadiusz Rak',
    position: 'Savings Product Manager',
    content:
      'Economics graduate at Lodz University. He has been in working in Alior Bank for 12 years. Currently responsible for development and maintenance of savings products. His extensive experience in sales, product & project management allows him to support additional business lines expansion. Always open to new challenges.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'wojciech_jarzembowski.jpg',
    name: 'Wojciech Jarzembowski',
    position: 'New Technology Manager',
    content:
      'A graduate of the Warsaw School of Economics. In the financial field for over 12 years. Most of his professional career associated with large financial institutions, where he gained knowledge in the area of Controlling and Projects Analysis. He has been working at Alior Bank since 2017, initially supporting internal initiatives with financial knowledge and contesting the hyperoptimistic PM’s plans. As an interesting experience, he recalls, financial verification of the investment in PayPo (BNPL company) under the Alior’s CVC fund and supervising Bank’s subsidiary- Bancovo. He ‘crossed the floor’  in 2022 by joining a newly established project that implements AI-based analytical tools and bots.',
    social: [{ name: '', url: '' }],
    email: '',
  },
  {
    img: 'anna_kulik.jpg',
    name: 'Anna Kulik',
    position: 'Head of Credit Department',
    content:
      'Anna has many years of experience in creating and selling credit products for individual clients. Her experience in the area of credit products combined with the development of digital credit processes has contributed to the development and implementation of innovative projects on the banking services market. Graduated from the Faculty of Banking and Finance as well as Marketing and Management at the University of Warsaw, passionate about coaching and yoga.',
    social: [{ name: '', url: '' }],
    email: '',
  },
];
const experts = [
  {
    img: 'ewa_grabowska.jpg',
    name: 'Ewa Grabowska',
    position: 'UX Design Manager',
    content:
      'Ewa leads a team of UX designers. She is responsible for the logic and functioning of digital products developed at the RBL_  Innovation Lab. She takes care of usability and make sure that solutions respond to the real needs of customers. Due to her journalistic education and experience in copywriting, she pays great attention to communication in line with the principles of UX writing. She is an attentive trend watcher and a fan of strategic foresight. She creates the RBL newsletter in which our employees can read the news about technology, innovation and banking of the future.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/ewa-grabowska-b9a514123',
      },
    ],
  },
  {
    img: 'katarzyna_kobylinska.jpg',
    name: 'Katarzyna Kobylińska',
    position: 'Director of Digitalization Development Department',
    content:
      'Expert in service design and customer experience management. She has been involved in banking for 12 years. Katarzyna graduated in Economics and Management studies at the University of Warsaw and Service Design at the SPWS University in Warsaw. Leader of Service Design and Innovation team. Together with the team, we are looking for innovative solutions in business based on tools from the world of UX and Service Design. We design for people and people are always at the center of attention. This means fully understanding his daily needs and providing a unique experience in interacting with the service, product, application.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://pl.linkedin.com/in/katarzyna-kobylinska',
      },
    ],
  },
  {
    img: 'katarzyna_turczyn.jpg',
    name: 'Katarzyna Turczyn',
    position: 'UX Research Manager',
    content:
      'Expert with many years of experience in research, who has devoted the last 5 of them to UX. Ethnologist and anthropologist, graduated from the University of Warsaw with Master Degree. Author of publications in the field of qualitative research and usability testing. Leader of research team, in which everyone feels as an advocate for users and customers and a promoter of the value of research in the development of products and services.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/katarzyna-turczyn/',
      },
    ],
  },
  {
    img: 'sergiusz_zbyszewski.jpg',
    name: 'Sergiusz Zbyszewski',
    position: 'Head of Strategic Partnerships Department',
    content:
      'Sergiusz has many years of experience in the field of sales and product for business customers.  Practitioner in building partnership cooperation models. A graduate of the University of Łódź, Faculty of Economics and Sociology, Warsaw School of Economics and Kozminski University, where he received his MBA. At present, he manages the Strategic Partnerships Department at Alior Bank creating key alliances with external partners for the bank.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/sergiusz-zbyszewski-mba-286911/',
      },
    ],
  },
  {
    img: 'tomasz_serwan.jpg',
    name: 'Tomasz Serwan',
    position: 'Head of Unit RBL_VC',
    content:
      'Tomasz is the head of the RBL_VC fund. Hej is responsible for the team management, creation and iplementarion of the strategy. Tomasz co-creates the Startup ecosystem since 2009. During his career in reputable VC funds, has carried out several dozen investments in companies from various industries. Many of them operate on a global scale. Acting as an independent specialist on the capital market, Tomasz was an advisor on mergers and acquisitions, both on the sale and purchase side. He was also responsible for creating strategies, process development and optimization for entities with high growth dynamics. During his career, he occupied a place „on both sides of the barricade”, both reporting and exercising ownership supervision. Last years he supported portfolio companies: PayPo and Autenti as an Supervisory Board Member.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/tomasz-serwan-5213333b/',
      },
    ],
  },
  {
    img: 'sebastian_bieniek.jpg',
    name: 'Sebastian Bieniek',
    position: 'Investment Specialist CVC',
    content:
      'Sebastian Bieniek belongs to the RBL_VC fund team, supporting it mainly by: investment potential analysis, market research and internal startups valuation. He worked as a Fund Manager in the first Polish independent investment fund company – OPERA TFI. He dealt with investments in innovative companies, both national and foreign. Sebastian holds a Polish Investment Advisor licence. ',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/sebastian-bieniek/',
      },
    ],
  },
  {
    img: 'jakub_maj.jpg',
    name: 'Jakub Maj',
    position: 'Investment Manager CVC',
    content:
      'Jakub Maj – Investment Manager, responsible for relationships with startups, VCs and the investment process.  He has as over 15 years of experience in incubation, venture capital and startup management. He worked for the leading German incubator Rocket Internet, performance marketing network AWIN in Germany (Axel Springer Group), and a daily-deal startup in Hungary. He gained his venture capital experience in reputable funds, including SevenVentures, the venture capital arm of ProSiebenSat1 in Germany, Bvalue.vc in Poland, and Rebate Networks in Germany. Jakub has been an advisor to startups in the field of venture capital, M&A, and business development. He was part of the investment committee at AIP, the leading Polish incubator, as well as part of the investment committee at National Center of Research and Development (NCBiR) in Poland.',
    social: [
      { name: 'LinkedIn', url: 'https://www.linkedin.com/in/jakubmaj/' },
    ],
  },
  {
    img: 'luiza_nowacka_samczuk.jpg',
    name: 'Luiza Nowacka-Samczuk',
    position: 'Investment Associate CVC at RBL_VC',
    content:
      "Luiza Nowacka-Samczuk is a part of RBL_VC's investment team, where her tasks include start-up’s scouting and recommending them for the investment. She co-manages the investment process, with particular focus on legal issues. For over 9 years Luiza has been co-creating a start-up ecosystem, currently acting on the side of the investor, previously providing business and legal advisory to startups. Moreover, her track-record includes the creation of over 200 business plans for a total investment amount of over PLN 2 billion. She has helped entrepreneurs to obtain funds for the implementation of their business projects (bank loans, VC funds, private investors, grants). She also worked at renowned Polish law firms specializing in M&A and new technology law. Founder of several start-ups. Juror of start-up competitions. A certified business negotiator with a degree from St Andrews University in Scotland. She completed doctoral studies at Warsaw School of Economics. ",
    social: [
      { name: 'LinkedIn', url: 'https://www.linkedin.com/in/luizanowacka/' },
    ],
  },
  {
    img: 'joanna_boron.jpg',
    name: 'Joanna Boroń',
    position: 'Open Banking Manager',
    content:
      'Finance graduate at SGH and a self-proclaimed banker. She has been in banking industry for a while now, usually right in between Technology and Business. This is where she earned her experience with business analysis, operations, product development and project management. Currently responsible for Open API and developing Open Banking products for Clients as well as creating further API-based services for TPPs and Partners. Avid enthusiast of agile mindset with keen interest in the impact of regulatory environment on Customers, Banking and FinTechs.',
    social: [
      { name: 'LinkedIn', url: 'https://pl.linkedin.com/in/joannaboron1' },
    ],
  },
  {
    img: 'grzegorz_jakubowski.jpg',
    name: 'Grzegorz Jakubowski',
    position: 'Open Banking Specialist',
    content:
      'Lawyer and English translator by profession, associated for 17 years with the financial services market. He gained experience in banking in Northern Ireland and England in the field of sales support, restructuring and debt collection as well as outsourcing. Currently, he is involved in cooperation with third parties (TPP) in connection with open banking services.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/grzegorz-jakubowski-6172a483/',
      },
    ],
  },
  {
    img: 'janusz_mucha.jpg',
    name: 'Mucha Janusz',
    position: 'Open Banking Expert',
    content:
      "Expert with several years of experience in cooperation between banks and their business partners: initially in servicing external distribution networks and credit guarantee funds, and after transfer to Alior - in implementation projects with FinTechs. Since 2019, he is responsible for the integration and maintenance of connections to banks' APIs in accordance with the PSD2 directive as well as the appliance and development of open banking services. He pays close attentnion to projects using AI, blockchain technology or cloud solutions.",
    social: [
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/janusz-mucha-593780133/',
      },
    ],
  },
  {
    img: 'kacper_rzeszutek.jpg',
    name: 'Kacper Rzeszutek',
    position: 'Business Unit Head Alior Mobile',
    content:
      'Engieneer, he graduate University of Science and Technology in Cracow and postgraduate studies: information systems and data analysis. He has been in banking industry since the beggining of his professional carrier, in Alior Bank since 2010. Experienced Manager in the areas of internet and mobile banking, as well as financial analysis and sales result reporting. Currently, he manage the Business Department of Alior Mobile. His main area of professional interest are potential VAS for the Alior Mobile app. Passionate about technology, innovation, banking of the future, and privately – football.',
    social: [
      {
        name: 'LinkedIn',
        url: 'https://pl.linkedin.com/in/kacper-rzeszutek-338399156',
      },
    ],
  },
];

export { liders, experts, mentors, rblTeam };
