export default {
    template: `
<div class="d-flex align-items-center justify-content-between">
    <div class="error">
        <svg class="me-2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V6.5H5.5V7.5ZM5.5 5.5H4.5V2.5H5.5V5.5Z" fill="#E25948"/>
        </svg>
        <span v-if="vfield.required === false">{{ required }} </span>
        <span v-if="vfield.maxLength === false">Please enter no more than {{ vfield.$params.maxLength.max }} characters. </span>
        <span v-if="vfield.minLength === false">Min. {{ vfield.$params.minLength.min }} digits. </span>
        <span v-if="vfield.numeric === false">Enter numbers only </span>
        <span v-if="vfield.url === false">Please specify a valid web link </span>
        <span v-if="vfield.email === false">Use email format (ex. jan@mail.com) </span>
    </div>
    <div v-if="counter && field.length && vfield.$params.maxLength" class="text-end ms-auto">
        <span class="text-muted" style="font-size: 12px;">{{ field.length }}/{{ vfield.$params.maxLength.max }} caracters</span>
    </div>
</div>
	`,
    name: 'Validation',
    props: {
        field: String,
        vfield: Object,
        required: {
            type: String,
            required: false,
            default: 'This field is required.'
        },
        counter: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    data() {
        return {
        }
    },
}