import './scss/main.scss';

import 'bootstrap/js/dist/collapse';
import Modal from 'bootstrap/js/dist/modal';
import Glide from '@glidejs/glide';
// import Vue from 'vue/dist/vue.min';
import Vue from 'vue/dist/vue';
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
import { liders, experts, mentors, rblTeam } from './team';
import FormComponent from './components/formComponent.js';
import Vuelidate from 'vuelidate';

let blockMenu = true;

Vue.use(VueFullPage);
Vue.use(Vuelidate);

new Vue({
  el: '#app',
  name: 'app',
  components: { FormComponent },
  data() {
    return {
      isMobile: true,
      navOpen: false,
      logos: [],
      modalLogos: 0,
      section: 0,
      options: {
        licenseKey: '56AAD8CB-8BE74F20-A9963F73-1B062220',
        scrollOverflow: true,
        afterLoad: this.afterLoad,
        onLeave: this.onLeave,
        responsiveWidth: 1024,
      },
      previousEditions: [
        {
          number: 1,
          date: '2018 - RBL_START-18',
          excerpt:
            'RBL_START 2018 was the first edition of acceleration program powered by Alior Bank.\n' +
            'Together with our key partner PZU we had been looking for solutions that would have taken\n' +
            'advantage of the benefits of open banking. At the same time, bringing the value to customers of\n' +
            'banking industry or insurance companies.',
          thumbnail: 'dist/images/edition1',
          url: 'editions.html',
        },
        {
          number: 2,
          date: '2019 - RBL_START-19',
          excerpt:
            'The second edition of the RBL_START accelerator took place in 2019 where we worked with our team\n' +
            'and many partners to find solutions to support our customers needs.',
          thumbnail: 'dist/images/edition2',
          url: 'editions.html',
        },
        {
          number: 3,
          date: '2020 - MORE THAN BANKING APP',
          excerpt:
            'In 2020, another edition of RBL_START was organized with the motto More that banking app. The\n' +
            'entire focus of our activities was directed towards bringing the modern mobile banking experience\n' +
            'closer to users.',
          thumbnail: 'dist/images/edition3',
          url: 'editions.html',
        },
      ],
      benefits: {
        active: 1,
      },
      accordion: {
        active: 1,
      },
      mentors: {
        active: {},
        items: mentors,
      },
      whatWeDo: {
        whyRblReadMore: false,
        howDoesItWork: {
          active: 1,
        },
      },
      pageTeam: {
        leadership: liders,
        fintech: experts,
      },
      pageRbl: {
        team: rblTeam,
      },
    };
  },
  watch: {
    navOpen(val) {
      if (val) {
        document.body.classList.add('nav-open');
      } else {
        document.body.classList.remove('nav-open');
      }
    },
  },
  created() {
    if (window.innerWidth > 992) {
      this.isMobile = false;
    }

    const logoCount = this.isMobile ? 6 : 17;
    for (let i = 0; i < logoCount; i++) {
      this.logos.push('logo' + (i + 1));
    }
  },
  methods: {
    modalIsOpen() {
      return document.body.classList.contains('modal-open');
    },

    onLeave(origin, destination, direction) {
      if (this.modalIsOpen()) {
        return false;
      }

      if (destination.isLast) {
        document.querySelector('.cross-sb').classList.add('d-none');
        document.querySelector('.cross-eb').classList.add('d-none');
      } else {
        document.querySelector('.cross-sb').classList.remove('d-none');
        document.querySelector('.cross-eb').classList.remove('d-none');
      }

      blockMenu = destination.index === 0;
      this.section = destination.index;
    },

    showLogoModal() {
      this.modalLogos = 19;
      const myModal = new Modal(document.getElementById('logotypesModal'), {
        keyboard: false,
      });
      myModal.show();
    },

    showMentorModal(mentor) {
      this.mentors.active = mentor;
      const myModal = new Modal(document.getElementById('mentorsModal'), {
        keyboard: false,
      });
      myModal.show();
    },
  },
});

if (document.querySelector('.glide')) {
  new Glide('.glide', {
    type: 'carousel',
    startAt: 0,
    perView: 3,
    gap: 20,
    breakpoints: {
      992: {
        perView: 2,
      },
      768: {
        perView: 1,
      },
    },
  }).mount();
}

if (document.querySelector('.glide-simple')) {
  new Glide('.glide-simple', {
    // type: 'carousel',
    // startAt: 0,
    // perView: 3,
    // gap: 20,
  }).mount();
}

// const nav = document.querySelector('.page-navigation');
// document.addEventListener("mousemove", function(event){
//     if (!nav) return;
//     if ( blockMenu ) {
//         nav.classList.add('show');
//         return;
//     }
//     if ( event.pageY < 50 ) {
//         nav.classList.add('show');
//     }
//     if ( event.pageY > 90 ) {
//         nav.classList.remove('show');
//     }
// });
